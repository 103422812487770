<template>
  <section class="uk-overflow-hidden uk-background-default uk-margin-medium-top uk-margin-medium-bottom">
    <div class="uk-container uk-container-large">
      <div v-if="isHome" class="uk-flex-center" data-uk-grid>
        <div class="uk-text-center uk-margin-medium-bottom">
          <h2 class="uk-heading-small uk-text-bold uk-margin-small-bottom uk-margin-top uk-margin-left uk-margin-right">
            {{ name }}
          </h2>
          <img
            class="uk-margin-left uk-margin-right uk-text-primary"
            style="height: 24px"
            src="@/web/img/brand/Star.svg"
            data-uk-svg
            alt="Varsity Base logo"
          />
          <p class="uk-text-lead uk-margin-auto-left uk-margin-auto-right" style="max-width: 600px">
            {{ description }}
          </p>
          <a
            class="uk-button uk-button-secondary uk-text-bold uk-display-inline-block vb-btn-hover-scale"
            style="border-radius: 48px"
            :href="linkUrl"
          >
            {{ linkText }}
          </a>
        </div>
      </div>
      <div
        class="uk-position-relative uk-visible-toggle"
        style="overflow: visible"
        tabindex="-1"
        data-uk-slider="autoplay: true; finite: true"
      >
        <div
          v-if="!isHome"
          class="uk-child-width-auto uk-flex-between uk-flex-wrap uk-margin-bottom uk-flex-bottom"
          data-uk-grid
        >
          <div>
            <h2 class="uk-h3 uk-text-bold uk-margin-remove-bottom uk-margin-left uk-margin-right">
              {{ name }}
            </h2>
          </div>
          <div>
            <ul class="uk-list uk-margin-remove uk-flex uk-flex-middle vb-slider-nav">
              <li class="uk-margin-remove-bottom uk-margin-remove-top">
                <a class="uk-text-small" :href="linkUrl">
                  <span class="uk-text-bold" style="border-bottom: 1px solid">{{ linkText }}</span>
                </a>
              </li>
              <li class="uk-margin-remove-bottom uk-margin-remove-top uk-margin-left uk-visible@s">
                <button
                  class="uk-icon-button uk-button-default uk-border-circle"
                  data-uk-icon="chevron-left"
                  data-uk-slider-item="previous"
                  type="button"
                ></button>
              </li>
              <li class="uk-margin-remove-bottom uk-margin-remove-top uk-margin-small-left uk-visible@s">
                <button
                  class="uk-icon-button uk-button-default uk-border-circle"
                  data-uk-icon="chevron-right"
                  data-uk-slider-item="next"
                  type="button"
                ></button>
              </li>
            </ul>
          </div>
        </div>

        <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-small" data-uk-grid>
          <product-card v-for="product in products" :key="product.id" :product="product" is-slider />
        </ul>
        <button
          class="uk-icon-button uk-border-circle uk-position-center-left uk-margin-small-left uk-margin-small-top"
          style="background-color: white; transform: translateY(-175%)"
          data-uk-icon="chevron-left"
          data-uk-slider-item="previous"
          type="button"
        />
        <button
          class="uk-icon-button uk-border-circle uk-position-center-right uk-margin-small-right uk-margin-small-top"
          style="background-color: white; transform: translateY(-175%)"
          data-uk-icon="chevron-right"
          data-uk-slider-item="next"
          type="button"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ProductCard from './ProductCard.vue'

export default {
  name: 'CollectionSlider',
  components: { ProductCard },
  props: {
    isHome: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    linkUrl: {
      type: String,
      default: '/',
    },
    linkText: {
      type: String,
      default: 'View all',
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
